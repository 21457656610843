import React from "react"
import {navigate} from 'gatsby';
import Rotate from 'react-reveal/Rotate';
import Fade from 'react-reveal/Fade';
import { Spinner } from 'react-bootstrap';

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage  extends React.Component {
  constructor() {
    super()

    this.state = {
      loaded: false,
      text: [],
      nextLoaded: false,
      finalLoaded: false
    }
  }

  componentDidMount() {
    const pageText = 'The Coastal Advisory Group delivers highly personal wealth management customized to your risk and goals. ' +
                     'We\'re successful because we take time to understand who you are, how you want to live today, and the ' +
                     'legacy you want to leave tomorrow. Our financial solutions are delivered in a style that is both comfortable ' +
                     'and comforting, based on the core values that we live by in life and in business.'
    
    let letters = [];
    let currentString = ''
    for(let i = 0; i <= pageText.length - 1; i++) {
      if(pageText.charAt(i) !== ' ') {
        currentString += pageText.charAt(i);
        if(i === pageText.length - 1) {
          letters.push(currentString)
        }
      } else {
        letters.push(currentString);
        letters.push(' ');
        currentString = '';
      }
    }
    setTimeout(()=> {
      this.setState({loaded: true, text: letters});
      setTimeout(() => {
        this.setState({nextLoaded: true});
        setTimeout(() => {
          this.setState({finalLoaded: true});
          setTimeout(() => {
            navigate('/home')
          }, 20000);
        }, 1500);
      }, 3000);
    }, 2000);

  }

  render() {
    console.log(this.state.text)
    return (
      <Layout location={this.props.location}>
        <SEO title="Welcome" description=""/>
        <div style={{paddingTop: '5%', paddingRight: '5%', paddingLeft: '5%', textAlign: 'center', color: 'black', minHeight: '450px'}}>
        {
          (this.state.loaded === true)
          ? (<div>
              {this.state.text.map((word, i, letters) => <Rotate top left><span>{letters[i]}</span></Rotate>)}
            </div>
            )
          : <Spinner animation="border" variant="dark" />
        }
        {
          (this.state.nextLoaded === true)
          ? <Fade><h4 style={{paddingTop: '5%', textAlign: 'center', color: 'rgb(141, 27, 56)'}}>Coastal Advisory Group</h4></Fade>
          : null
        }
        {
          (this.state.finalLoaded === true)
          ? <Fade><h4 style={{paddingTop: '5%', textAlign: 'center', color: 'rgb(141, 27, 56)'}}>Where You <span style={{fontStyle: "italic"}}>And</span> Your Money Are Treated Best</h4></Fade>
          : null
        }
        </div>
      </Layout>
   )
  }
}

export default IndexPage